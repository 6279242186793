<template>
  <MDBNavbar dark bg="dark" expand="lg" container position="top">
    <MDBNavbarBrand href="/">
      <NuxtImg src="/logo.svg" width="50" height="50" alt="" />
      <span class="visually-hidden">{{ title }}</span>
    </MDBNavbarBrand>
    <MDBNavbarToggler
      target="#navbarNav"
      @click="collapse2 = !collapse2"
      class="text-white"
    ></MDBNavbarToggler>
    <MDBCollapse id="navbarNav" v-model="collapse2">
      <MDBNavbarNav right>
        <template
          v-for="({ text, url, external, disabled }, index) in navigation"
          :key="index"
        >
          <MDBNavbarItem
            v-if="external"
            :href="url"
            :disabled="disabled ?? false"
            active
          >
            {{ text }}
          </MDBNavbarItem>
          <MDBNavbarItem
            v-else="item.external"
            :to="url"
            :disabled="disabled ?? false"
            active
          >
            {{ text }}
          </MDBNavbarItem>
        </template>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
</template>

<script lang="ts" setup>
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-vue-ui-kit";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const collapse2 = ref(false);
const { navigation } = useAppConfig();
const { title } = useAppConfig();

onMounted(() => {
  library.add(faBars);
  dom.i2svg({ node: document.querySelector(".navbar-toggler")! });
});
</script>
