<template>
  <MDBRow>
    <MDBCol col="12" class="copyright py-3 text-white">
      Copyright &copy; 2024 &mdash; Luke Nelson
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
</script>

<style lang="scss">
@use "../assets/styles/variables" as v;

.copyright {
  text-align: right;
  background-color: v.$black;
  font-size: small;
}
</style>
