<template>
  <MDBCol col="12" lg="auto" class="py-3">
    <h3>{{ props.title }}</h3>
    <slot></slot>
  </MDBCol>
</template>

<script setup lang="ts">
import { MDBCol } from "mdb-vue-ui-kit";
const props = defineProps<{
  title: string;
}>();
</script>

<style lang="scss" scoped>
h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 300;
}
</style>
